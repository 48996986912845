import React from 'react';
import { Helmet } from 'react-helmet';

const FluentValidationRedirect = () => {
  return (
    <>
      <Helmet>
        <script type="application/javascript">
          window.location.href = 'https://fluentvalidation-ts.alexpotter.dev';
        </script>
      </Helmet>
      Redirecting...
    </>
  );
};

export default FluentValidationRedirect;
